



















import Vue from 'vue';
import { MusicPlayerStatus } from '@/store/types';

export default Vue.extend({
  name: 'StakeMusicPlayer',
  data() {
    return {
      list: [
        '/music/1.mp3',
        '/music/2.mp3',
      ],
      currentIndex: 0,
    };
  },
  computed: {
    src(): string {
      return this.list[this.currentIndex];
    },
    isPlaying(): boolean {
      return this.$store.getters.musicPlayerStatus === MusicPlayerStatus.Playing;
    },
    isFirstSong(): boolean {
      return this.currentIndex === 0;
    },
  },
  methods: {
    playOrPause() {
      if (this.$store.getters.musicPlayerStatus === MusicPlayerStatus.Pausing) {
        this.$store.commit('changeMusicPlayerStatus', MusicPlayerStatus.Playing);
        (this.$refs.audio as any).play();
      } else {
        this.$store.commit('changeMusicPlayerStatus', MusicPlayerStatus.Pausing);
        (this.$refs.audio as any).pause();
      }
    },
    next() {
      if (!this.isPlaying) {
        return;
      }
      let index = this.currentIndex + 1;
      if (index === this.list.length) {
        index = 0;
      }
      this.currentIndex = index;
    },
  },
  mounted() {
    (this.$refs.audio as any).pause();
  },
});
