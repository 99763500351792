import { MusicPlayerStatus } from '@/store/types';
import { State } from './state';

const getters = {
  apy1: (state: State): number => state.stake.apy1,
  apy2: (state: State): number => state.stake.apy2,
  apy3: (state: State): number => state.stake.apy3,
  apy4: (state: State): number => state.stake.apy4,
  apy5: (state: State): number => state.stake.apy5,
  apy6: (state: State): number => state.stake.apy6,
  apy7: (state: State): number => state.stake.apy7,
  apy8: (state: State): number => state.stake.apy8,
  musicPlayerStatus: (state: State): MusicPlayerStatus => state.musicPlayerStatus,
};

export default getters;
