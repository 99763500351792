










































































































































import Vue from 'vue';
import moment from 'moment';
import { MusicPlayerStatus } from '@/store/types';
import doki from '../../utils/abis/tokens/doki_token';
import dokiPool from '../../utils/abis/pools/doki/doki_pool';
import uniPair from '../../utils/abis/tokens/doki_eth_pair';
import uniPairPool from '../../utils/abis/pools/doki/doki_eth_pool';
import wbtc from '../../utils/abis/tokens/wbtc_token';
import wbtc2dokiPool from '../../utils/abis/pools/doki/wbtc_pool';
import weth from '../../utils/abis/tokens/weth_token';
import weth2dokiPool from '../../utils/abis/pools/doki/weth_pool';

import azuki from '../../utils/abis/tokens/azuki_token';
import azukiEthPair from '../../utils/abis/tokens/azuki_eth_pair';
import aDokiPool from '../../utils/abis/pools/azuki/a_doki_pool';
import aDokiEthPool from '../../utils/abis/pools/azuki/a_doki_eth_pool';
import azukiPool from '../../utils/abis/pools/azuki/azuki_pool';
import azukiEthPool from '../../utils/abis/pools/azuki/azuki_eth_pool';

declare interface Contract {
  address: string,
  ABI: any
}

export default Vue.extend({
  name: 'StakeContent',
  props: {
    pool: String,
  },
  data(): {
    halvingTime: number,
    haveStarted: boolean,
    balance: number,
    rewardsAmount: number,
    rewardPerToken: number,
    rewardRate: number,
    rewards: number,
    totalLpSupply: number,
    lpTokenBalance: number,
    poolDistribution: string,
    currentApy: string,
    riskLevel: string,
    rewardLevel: string,
    currentAccount: string,
    amount: string,
    approvedCount: number,
    operation: any,
    result: string,
    timer: any,
    lpToken: Contract,
    contract: Contract
    suffix: string,
    decimals: number,
    dialog: boolean,
    dialogMessage: string,
    tokenType: string,
    } {
    return {
      halvingTime: 0,
      haveStarted: false,
      balance: 0,
      rewardsAmount: 0,
      rewardPerToken: 0,
      rewardRate: 0,
      rewards: 0,
      totalLpSupply: 0,
      lpTokenBalance: 0,
      poolDistribution: '0',
      currentApy: '',
      riskLevel: '',
      rewardLevel: '',
      currentAccount: '',
      amount: '',
      approvedCount: 0,
      operation: '',
      result: '',
      timer: '',
      lpToken: { address: '', ABI: [] },
      contract: { address: '', ABI: [] },
      suffix: '',
      decimals: 1,
      dialog: false,
      dialogMessage: '',
      tokenType: '',
    };
  },
  computed: {
    dokiPoolId(): string {
      return this.$route.params.id;
    },

    showInput() {
      if (this.amount === '') {
        return false;
      }
      return true;
    },

    myRewards(): number {
      return this.maxDecimal(this.rewardsAmount / 1e18);
    },

    stakedCount(): number {
      return this.maxDecimal(this.balance / this.decimals);
    },

    totalStakedCount(): number {
      return this.maxDecimal(this.totalLpSupply / this.decimals);
    },

    approveAmount(): number {
      return this.maxDecimal(this.approvedCount / this.decimals);
    },

    canApproveCount(): number {
      return this.maxDecimal(this.lpTokenBalance / this.decimals);
    },

    costAmount(): string {
      if (Number.isNaN(Number(this.amount))) {
        return 'Please input a number';
      }
      return `Your input: ${this.amount}`;
    },

    rewardsOneDay(): number {
      if (this.totalStakedCount !== 0) {
        const oneDayAmount = (this.rewardRate * 24 * 3600 * this.stakedCount)
          / this.totalStakedCount;
        return this.maxDecimal(oneDayAmount / 1e18);
      }
      return 0;
    },

    timeToHalving(): string {
      const halvingDate = moment(this.halvingTime * 1000).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      return halvingDate;
    },
    distribution(): number {
      const dokiAmount = this.rewardRate * 24 * 3600;
      return this.maxDecimal(dokiAmount / 1e18);
    },
    isPlaying(): boolean {
      return this.$store.getters.musicPlayerStatus === MusicPlayerStatus.Playing;
    },
  },
  methods: {
    maxDecimal(num: number) {
      return Number(String(num).replace(/^(.*\..{4}).*$/, '$1'));
    },

    async loadData() {
      if (this.dokiPoolId === '1') {
        this.currentApy = this.$store.getters.apy1;
      } else if (this.dokiPoolId === '2') {
        this.currentApy = this.$store.getters.apy2;
      } else if (this.dokiPoolId === '3') {
        this.currentApy = this.$store.getters.apy3;
      } else if (this.dokiPoolId === '4') {
        this.currentApy = this.$store.getters.apy4;
      } else if (this.dokiPoolId === '5') {
        this.currentApy = this.$store.getters.apy5;
      } else if (this.dokiPoolId === '6') {
        this.currentApy = this.$store.getters.apy6;
      } else if (this.dokiPoolId === '7') {
        this.currentApy = this.$store.getters.apy7;
      } else if (this.dokiPoolId === '8') {
        this.currentApy = this.$store.getters.apy8;
      }

      try {
        if (this.$store.state.web3 === '') {
          this.currentAccount = 'Please connect MetaMask';
          return;
        }
        const { web3 } = this.$store.state;

        const accounts = await web3.eth.getAccounts();
        this.currentAccount = `${accounts[0]}`;
        if (accounts.length === 0) {
          this.$store.commit('setWeb3', '');
          this.currentAccount = 'Please connect MetaMask';
        }
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        const lpToken = new web3.eth.Contract(
          this.lpToken.ABI,
          this.lpToken.address,
        );
        this.lpTokenBalance = await lpToken.methods
          .balanceOf(this.currentAccount)
          .call();
        this.halvingTime = await stakeContract.methods.halvingTime().call();
        this.haveStarted = await stakeContract.methods.haveStarted().call();
        this.balance = await stakeContract.methods
          .balanceOf(this.currentAccount)
          .call();
        this.rewardPerToken = await stakeContract.methods
          .rewardPerToken()
          .call();
        this.rewardRate = await stakeContract.methods.rewardRate().call();
        this.rewardsAmount = await stakeContract.methods
          .getRewardsAmount(this.currentAccount)
          .call();
        this.totalLpSupply = await stakeContract.methods.totalSupply().call();
        await this.getApproved();
        // const initialReward = await stakeContract.methods.initReward().call();
      } catch (error) {
        console.log(error);
      }
    },

    checkStart() {
      if (!this.haveStarted) {
        this.dialogMessage = 'Have not started!';
        this.dialog = true;
        return false;
      }
      return true;
    },

    checkInput(amount: string) {
      if (Number.isNaN(Number(amount))) {
        this.dialogMessage = 'Please input a number!';
        this.dialog = true;
        return false;
      }
      if (Number(amount) === 0) {
        this.dialogMessage = 'You input 0!';
        this.dialog = true;
        return false;
      }
      return true;
    },

    async approve() {
      try {
        this.operation = 'Approve All ...';
        this.result = '';
        const { web3 } = this.$store.state;
        const lpToken = new web3.eth.Contract(
          this.lpToken.ABI,
          this.lpToken.address,
        );
        const approveAmount = this.tokenType === 'DOKI' ? '50000000000000000000000'
          : '999999999999000000000000000000';
        await lpToken.methods
          .approve(this.contract.address, web3.utils.toBN(approveAmount))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.getApproved();
        await this.loadData();
      } catch (error) {
        console.log(error);
        this.result = 'Failure';
      }
    },
    async getApproved() {
      const { web3 } = this.$store.state;
      if (web3 === '') {
        return;
      }

      try {
        const lpToken = new web3.eth.Contract(
          this.lpToken.ABI,
          this.lpToken.address,
        );
        this.approvedCount = await lpToken.methods
          .allowance(this.currentAccount, this.contract.address)
          .call();
      } catch (err) {
        console.log(err);
      }
    },

    async stake(amount: number) {
      if (!this.checkStart()) {
        return;
      }
      if (!this.checkInput(String(amount))) {
        return;
      }
      if (Number(amount) > this.approvedCount) {
        this.dialogMessage = 'Approved Balance < Stake Amount.';
        this.dialog = true;
        return;
      }
      const { web3 } = this.$store.state;
      try {
        this.operation = `Staking ${amount} ...`;
        this.result = '';
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        let lpTokenCount = '0';
        if (this.decimals === 1e18) {
          lpTokenCount = `${amount}000000000000000000`;
        } if (this.decimals === 1e8) {
          lpTokenCount = `${amount}00000000`;
        }
        // const lpTokenCount = amount + this.decimals;
        await stakeContract.methods
          .stake(web3.utils.toBN(lpTokenCount))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.getApproved();
        await this.loadData();
      } catch (error) {
        console.log(error);
        this.result = 'failure';
      }
    },
    async stakeAll() {
      if (!this.checkStart()) {
        return;
      }
      try {
        const { web3 } = this.$store.state;
        this.operation = `Staking ${this.lpTokenBalance / this.decimals} ...`;
        this.result = '';
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        await stakeContract.methods
          .stake(web3.utils.toBN(this.lpTokenBalance))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.getApproved();
        await this.loadData();
      } catch (error) {
        console.log(error);
        this.result = 'failure';
      }
    },
    async claim(amount: number) {
      if (!this.checkStart()) {
        return;
      }
      if (!this.checkInput(String(amount))) {
        return;
      }
      if (Number(amount) * 1e18 > this.rewardsAmount) {
        this.dialogMessage = 'Claim Balance < Claim Amount';
        this.dialog = true;
        return;
      }
      try {
        const { web3 } = this.$store.state;
        this.operation = `Claim ${amount} ...`;
        this.result = '';
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        const dokiAmount = `${amount}000000000000000000`;
        await stakeContract.methods
          .claim(web3.utils.toBN(dokiAmount))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.loadData();
      } catch (error) {
        console.log(error);
        this.result = 'failure';
      }
    },
    async claimAll() {
      if (!this.checkStart()) {
        return;
      }
      try {
        const { web3 } = this.$store.state;
        this.operation = `Claim ${this.rewardsAmount / 1e18} ...`;
        this.result = '';
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        await stakeContract.methods
          .claim(web3.utils.toBN(this.rewardsAmount))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.loadData();
      } catch (error) {
        this.result = 'failure';
      }
    },
    async withdraw(amount: number) {
      if (!this.checkStart()) {
        return;
      }
      if (!this.checkInput(String(amount))) {
        return;
      }
      if (Number(amount) * this.decimals > this.balance) {
        this.dialogMessage = 'No enough approved balance.';
        this.dialog = true;
        return;
      }
      try {
        const { web3 } = this.$store.state;
        this.operation = `Withdraw ${amount} ...`;
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        let lpTokenCount = '0';
        if (this.decimals === 1e18) {
          lpTokenCount = `${amount}000000000000000000`;
        } if (this.decimals === 1e8) {
          lpTokenCount = `${amount}00000000`;
        }

        console.log(`The lp token amount is ${lpTokenCount} - ${this.decimals}`);

        await stakeContract.methods
          .withdraw(web3.utils.toBN(lpTokenCount))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.loadData();
      } catch (error) {
        this.result = 'failure';
      }
    },
    async withdrawAll() {
      if (!this.checkStart()) {
        return;
      }
      try {
        const { web3 } = this.$store.state;
        this.operation = `Withdraw ${this.balance / this.decimals} ${this.suffix
        } ...`;
        const stakeContract = new web3.eth.Contract(
          this.contract.ABI,
          this.contract.address,
        );
        await stakeContract.methods
          .withdraw(web3.utils.toBN(`${this.balance}`))
          .send({ from: this.currentAccount });
        this.result = 'Success';
        await this.loadData();
      } catch (error) {
        this.result = 'failure';
      }
    },
  },
  async created() {
    this.timer = setInterval(await this.loadData, 3000);
  },
  async activated() {
    if (this.dokiPoolId === '1') {
      this.poolDistribution = '14,500';
      this.lpToken = { address: doki.address, ABI: doki.ABI };
      this.contract = dokiPool;
      this.suffix = 'DOKI';
      this.decimals = 1e18;
      this.riskLevel = 'Medium';
      this.rewardLevel = 'Medium';
      this.tokenType = 'DOKI';
    } else if (this.dokiPoolId === '2') {
      this.poolDistribution = '27,000';
      this.lpToken = uniPair;
      this.contract = uniPairPool;
      this.suffix = 'DOKI/ETH LP';
      this.decimals = 1e18;
      this.riskLevel = 'High';
      this.rewardLevel = 'High';
      this.tokenType = 'DOKI';
    } else if (this.dokiPoolId === '3') {
      this.poolDistribution = '1000';
      this.lpToken = wbtc;
      this.contract = wbtc2dokiPool;
      this.suffix = 'WBTC';
      this.decimals = 1e8;
      this.riskLevel = 'Low';
      this.rewardLevel = 'Low';
      this.tokenType = 'DOKI';
    } else if (this.dokiPoolId === '4') {
      this.poolDistribution = '1000';
      this.lpToken = weth;
      this.contract = weth2dokiPool;
      this.suffix = 'WETH';
      this.decimals = 1e18;
      this.riskLevel = 'Low';
      this.rewardLevel = 'Low';
      this.tokenType = 'DOKI';
    } else if (this.dokiPoolId === '5') {
      this.poolDistribution = '3,000,000';
      this.lpToken = doki;
      this.contract = aDokiPool;
      this.suffix = 'DOKI';
      this.decimals = 1e18;
      this.riskLevel = 'Medium';
      this.rewardLevel = 'Medium';
      this.tokenType = 'AZUKI';
    } else if (this.dokiPoolId === '6') {
      this.poolDistribution = '4,000,000';
      this.lpToken = uniPair;
      this.contract = aDokiEthPool;
      this.suffix = 'DOKI/ETH LP';
      this.decimals = 1e18;
      this.riskLevel = 'Medium';
      this.rewardLevel = 'Medium';
      this.tokenType = 'AZUKI';
    } else if (this.dokiPoolId === '7') {
      this.poolDistribution = '4,000,000';
      this.lpToken = azuki;
      this.contract = azukiPool;
      this.suffix = 'AZUKI';
      this.decimals = 1e18;
      this.riskLevel = 'Medium';
      this.rewardLevel = 'Medium';
      this.tokenType = 'AZUKI';
    } else if (this.dokiPoolId === '8') {
      this.poolDistribution = '8,000,000';
      this.lpToken = azukiEthPair;
      this.contract = azukiEthPool;
      this.suffix = 'AZUKI/ETH LP';
      this.decimals = 1e18;
      this.riskLevel = 'High';
      this.rewardLevel = 'High';
      this.tokenType = 'AZUKI';
    }

    await this.loadData();
    await this.getApproved();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
});
