





























































import Vue from 'vue';

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      dialogMessage: '',
      dialog: false,
    };
  },
  methods: {
    clickCover(): void {
      this.dialog = true;
      this.dialogMessage = 'Coming soon';
    },
    clickBank(): void {
      this.dialog = true;
      this.dialogMessage = 'Coming soon';
    },
    clickTitle(): void {
      this.dialogMessage = 'ようこそ。 Welcome to dokidoki finance';
      this.dialog = true;
    },
    clickSwap(): void {
      window.open('https://degacha.com/');
    },
    clickStake(): void {
      const { href } = window.location;
      window.location.href = `${href}stake`;
    },
    clickAbout(): void {
      this.dialogMessage = '';
      this.dialog = true;
    },
    // click links
    openDiscord(): void {
      window.open('https://discord.gg/K32BeEY');
    },
    openTelegram(): void {
      window.open('https://t.me/ddnfg');
    },
    openMedium(): void {
      window.open('https://medium.com/@dokidoki.finance');
    },
    openTwitter(): void {
      window.open('https://twitter.com/dokidokifinance');
    },
    isMobile(): boolean {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
      );
      return flag !== null;
    },
  },
  mounted() {
    if (this.isMobile()) {
      this.dialogMessage = 'Landscape Mode or PC recommended';
      this.dialog = true;
    }
  },
});
