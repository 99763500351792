






































































































































import Vue from 'vue';
import {
  ChainId, Fetcher, Route, Token, WETH,
} from '@uniswap/sdk';
import dokiPool from '@/utils/abis/pools/doki/doki_pool';
import uniPairPool from '@/utils/abis/pools/doki/doki_eth_pool';
import wbtc2dokiPool from '@/utils/abis/pools/doki/wbtc_pool';
import weth2dokiPool from '@/utils/abis/pools/doki/weth_pool';
import aDokiPool from '@/utils/abis/pools/azuki/a_doki_pool';
import aDokiEthPool from '@/utils/abis/pools/azuki/a_doki_eth_pool';
import azukiPool from '@/utils/abis/pools/azuki/azuki_pool';
import azukiEthPool from '@/utils/abis/pools/azuki/azuki_eth_pool';

export default Vue.extend({
  name: 'StakeInfo',
  data() {
    return {
      doki2ethPrice: 0,
      eth2dokiPrice: 0,
      btc2ethPrice: 0,
      lpTokenPrice: 0,
      doki2usdtPrice: 0,
      azuki2ethPrice: 0,
      eth2azukiPrice: 0,
      azuki2usdtPrice: 0,
      timer: '',
    };
  },
  methods: {
    maxDecimal(number: number) {
      return String(number).replace(/^(.*\..{2}).*$/, '$1');
    },

    async getPrice() {
      const DOKI = new Token(
        ChainId.MAINNET,
        '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544',
        18,
      );
      const pair = await Fetcher.fetchPairData(DOKI, WETH[DOKI.chainId]);
      const route = new Route([pair], WETH[DOKI.chainId]);
      const doki2ethPrice = route.midPrice.invert().toSignificant(4);
      const eth2dokiPrice = route.midPrice.toSignificant(4);
      this.doki2ethPrice = Number(doki2ethPrice);
      this.eth2dokiPrice = Number(eth2dokiPrice);

      const USDT = new Token(
        ChainId.MAINNET,
        '0xdac17f958d2ee523a2206206994597c13d831ec7',
        6,
      );
      const ethAndUsdt = await Fetcher.fetchPairData(USDT, WETH[USDT.chainId]);
      const ethAndUsdtRoute = new Route([ethAndUsdt], WETH[USDT.chainId]);

      const WBTC = new Token(
        ChainId.MAINNET,
        '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        8,
      );
      const ethAndWbtc = await Fetcher.fetchPairData(WBTC, WETH[WBTC.chainId]);
      const ethAndWbtcRoute = new Route([ethAndWbtc], WETH[WBTC.chainId]);
      const wbtc2ethPrice = ethAndWbtcRoute.midPrice.invert().toSignificant(4);
      this.btc2ethPrice = Number(wbtc2ethPrice);

      const doki2usdtPrice = Number(doki2ethPrice)
        * Number(ethAndUsdtRoute.midPrice.toSignificant(4));
      this.doki2usdtPrice = Number(doki2usdtPrice);
    },

    async getPriceOfAzuki() {
      const AZUKI = new Token(
        ChainId.MAINNET,
        '0x910524678C0B1B23FFB9285a81f99C29C11CBaEd',
        18,
      );
      const pair = await Fetcher.fetchPairData(AZUKI, WETH[AZUKI.chainId]);
      const route = new Route([pair], WETH[AZUKI.chainId]);
      const azuki2ethPrice = route.midPrice.invert().toSignificant(4);
      const eth2azukiPrice = route.midPrice.toSignificant(4);
      this.azuki2ethPrice = Number(azuki2ethPrice);
      this.eth2azukiPrice = Number(eth2azukiPrice);

      const USDT = new Token(
        ChainId.MAINNET,
        '0xdac17f958d2ee523a2206206994597c13d831ec7',
        6,
      );
      const ethAndUsdt = await Fetcher.fetchPairData(USDT, WETH[USDT.chainId]);
      const ethAndUsdtRoute = new Route([ethAndUsdt], WETH[USDT.chainId]);
      const azuki2usdtPrice = Number(azuki2ethPrice)
        * Number(ethAndUsdtRoute.midPrice.toSignificant(4));
      this.azuki2usdtPrice = Number(azuki2usdtPrice);
    },

    async initAPY() {
      if (this.$store.state.web3 === '') {
        return;
      }

      const { web3 } = this.$store.state;
      const pool1 = new web3.eth.Contract(dokiPool.ABI, dokiPool.address);
      const pool1Stake = await pool1.methods.totalSupply().call();
      const pool1RewardRate = await pool1.methods.rewardRate().call();
      if (pool1Stake === '0') {
        this.$store.commit('setupApy1', '5300');
      } else {
        const APY1 = (pool1RewardRate * 3600 * 24 * 365 * this.doki2ethPrice)
          / (pool1Stake * this.doki2ethPrice);
        this.$store.commit('setupApy1', this.maxDecimal(APY1 * 100));
      }

      const pool2 = new web3.eth.Contract(uniPairPool.ABI, uniPairPool.address);
      const pool2Stake = await pool2.methods.totalSupply().call();
      const pool2RewardRate = await pool2.methods.rewardRate().call();
      if (pool2Stake === '0') {
        this.$store.commit('setupApy2', '9630');
      } else {
        const dokiEthPairPrice = await this.getDokiEthPairPrice();
        const APY2 = (pool2RewardRate * 3600 * 24 * 365 * this.doki2ethPrice) / (pool2Stake
          * dokiEthPairPrice);
        this.$store.commit('setupApy2', this.maxDecimal(APY2 * 100));
      }

      const pool3 = new web3.eth.Contract(
        wbtc2dokiPool.ABI,
        wbtc2dokiPool.address,
      );
      const pool3Stake = await pool3.methods.totalSupply().call();
      const pool3RewardRate = await pool3.methods.rewardRate().call();
      if (pool3Stake === '0') {
        this.$store.commit('setupApy3', '1500');
      } else {
        const APY3 = ((pool3RewardRate / 1e18) * 3600 * 24 * 365 * this.doki2ethPrice)
          / ((pool3Stake / 1e8) * this.btc2ethPrice);
        this.$store.commit('setupApy3', this.maxDecimal(APY3 * 100));
      }

      const pool4 = new web3.eth.Contract(
        weth2dokiPool.ABI,
        weth2dokiPool.address,
      );
      const pool4Stake = await pool4.methods.totalSupply().call();
      const pool4RewardRate = await pool4.methods.rewardRate().call();
      if (pool4Stake === '0') {
        this.$store.commit('setupApy4', '1500');
      } else {
        const APY4 = (pool4RewardRate * 3600 * 24 * 365 * this.doki2ethPrice)
          / pool4Stake;
        this.$store.commit('setupApy4', this.maxDecimal(APY4 * 100));
      }
    },

    async initAzukiAPY() {
      if (this.$store.state.web3 === '') {
        return;
      }

      const { web3 } = this.$store.state;
      const pool1 = new web3.eth.Contract(aDokiPool.ABI, aDokiPool.address);
      const pool1Stake = await pool1.methods.totalSupply().call();
      const pool1RewardRate = await pool1.methods.rewardRate().call();
      if (pool1Stake === '0') {
        this.$store.commit('setupApy5', '4000');
      } else {
        const APY5 = (pool1RewardRate * 3600 * 24 * 365 * this.azuki2ethPrice)
          / (pool1Stake * this.doki2ethPrice);
        this.$store.commit('setupApy5', this.maxDecimal(APY5 * 100));
      }

      const pool2 = new web3.eth.Contract(aDokiEthPool.ABI, aDokiEthPool.address);
      const pool2Stake = await pool2.methods.totalSupply().call();
      const pool2RewardRate = await pool2.methods.rewardRate().call();
      const dokiEthPairPrice = await this.getDokiEthPairPrice();
      if (pool2Stake === '0') {
        this.$store.commit('setupApy6', '8000');
      } else {
        const APY6 = (pool2RewardRate * 3600 * 24 * 365 * this.azuki2ethPrice) / (pool2Stake
          * dokiEthPairPrice) / 2;
        this.$store.commit('setupApy6', this.maxDecimal(APY6 * 100));
      }

      const pool3 = new web3.eth.Contract(
        azukiPool.ABI,
        azukiPool.address,
      );
      const pool3Stake = await pool3.methods.totalSupply().call();
      const pool3RewardRate = await pool3.methods.rewardRate().call();
      if (pool3Stake === '0') {
        this.$store.commit('setupApy7', '8000');
      } else {
        const APY7 = (pool3RewardRate * 3600 * 24 * 365 * this.azuki2ethPrice)
          / (pool3Stake * this.azuki2ethPrice);
        this.$store.commit('setupApy7', this.maxDecimal(APY7 * 100));
      }

      const pool4 = new web3.eth.Contract(
        azukiEthPool.ABI,
        azukiEthPool.address,
      );
      const pool4Stake = await pool4.methods.totalSupply().call();
      const pool4RewardRate = await pool4.methods.rewardRate().call();
      const azukiEthPairPrice = await this.getAzukiEthPairPrice();
      if (pool4Stake === '0') {
        this.$store.commit('setupApy8', '15000');
      } else {
        const APY8 = (pool4RewardRate * 3600 * 24 * 365 * this.azuki2ethPrice)
          / (pool4Stake * azukiEthPairPrice) / 2;
        this.$store.commit('setupApy8', this.maxDecimal(APY8 * 100));
      }
    },

    async getDokiEthPairPrice() {
      const DOKI = new Token(
        ChainId.MAINNET,
        '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544',
        18,
      );
      const pair = await Fetcher.fetchPairData(DOKI, WETH[DOKI.chainId]);
      const route = new Route([pair], WETH[DOKI.chainId]);
      // 1 eth buy 8.1 doki
      const ethToDokiRatio = route.midPrice.toFixed();
      // console.log(`pair price is ${pairPrice}`);
      return 2 / (Math.sqrt(Number(ethToDokiRatio)));
    },

    async getAzukiEthPairPrice() {
      const DOKI = new Token(
        ChainId.MAINNET,
        '0x910524678c0b1b23ffb9285a81f99c29c11cbaed',
        18,
      );
      const pair = await Fetcher.fetchPairData(DOKI, WETH[DOKI.chainId]);
      const route = new Route([pair], WETH[DOKI.chainId]);
      const ethToDokiRatio = route.midPrice.toFixed();
      // console.log(`pair price is ${pairPrice}`);
      return 2 / (Math.sqrt(Number(ethToDokiRatio)));
    },
  },

  async created() {
    await this.getPrice();
    await this.initAPY();
    await this.getPriceOfAzuki();
    await this.initAzukiAPY();
  },
});
