

































import Vue from 'vue';
import StakeInfo from '@/components/stake/StakeInfo.vue';
import StakeMusicPlayer from '@/components/stake/StakeMusicPlayer.vue';
import StakeNav from '../components/stake/StakeNav.vue';
import getWeb3 from '../utils/GetWeb3';

export default Vue.extend({
  name: 'Stake',
  components: {
    StakeNav,
    StakeInfo,
    StakeMusicPlayer,
  },
  computed: {
    isInstallMatemask() {
      return this.$store.state.stake.web3 !== '';
    },
  },
  methods: {
    async connectMetamask() {
      const web3 = await getWeb3();
      this.$store.commit('setupWeb3', web3);
    },
  },
  async created() {
    await this.connectMetamask();
  },
});
