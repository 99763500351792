export interface StakeInfo {
  apy1: number,
  apy2: number,
  apy3: number,
  apy4: number,
  apy5: number,
  apy6: number,
  apy7: number,
  apy8: number
}

export enum MusicPlayerStatus {
  Playing,
  Pausing,
}
