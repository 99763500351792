import Web3 from 'web3';

const getWeb3 = async () => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    return web3;
  } if (window.web3) {
    const web3 = new Web3((window.web3 as any).currentProvider);
    return web3;
  }
  alert('Please install Metamask.');
  return '';
};

export default getWeb3;
