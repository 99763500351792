import { MusicPlayerStatus, StakeInfo } from './types';

export interface State {
  web3: any,
  stake: StakeInfo
  musicPlayerStatus: MusicPlayerStatus
}

const state: State = {
  web3: '',
  stake: {
    apy1: 0,
    apy2: 0,
    apy3: 0,
    apy4: 0,
    apy5: 0,
    apy6: 0,
    apy7: 0,
    apy8: 0,
  },
  musicPlayerStatus: MusicPlayerStatus.Pausing,
};

export default state;
