<template>
  <div id="app">
    <v-app :style="{width: width}">
      <router-view/>
    </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      width: '100%',
    };
  },
  created() {
    const windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (windowWidth < 600) {
      this.width = '800px';
    } else {
      this.width = '100%';
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin: 0;
  .v-application {
    line-height: 0.7vw;
    p {
      line-height: 0.8vw;
      margin-bottom: 0;
    }
  }
}
</style>
