const address = '0xdf4F609134a84aae1D18dCe8d863b099c6455598';

const ABI = [{
  inputs: [{
    internalType: 'address',
    name: '_azuki',
    type: 'address',
  }, { internalType: 'address', name: '_lpToken', type: 'address' }],
  stateMutability: 'nonpayable',
  type: 'constructor',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'to', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'Claim',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'Decreasing',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
  }],
  name: 'OwnershipTransferred',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'Stake',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'Start',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'to', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'Withdraw',
  type: 'event',
}, {
  inputs: [],
  name: 'DURATION',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'azukiAddress',
  outputs: [{ internalType: 'address', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
  name: 'balanceOf',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'claim',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'devAddr',
  outputs: [{ internalType: 'address', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'devDistributeDuration',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'devDistributeRate',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'devFinishTime',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'devFundAmount',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
  name: 'getRewardsAmount',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'halvingTime',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'haveStarted',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'initReward',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'lastDistributeTime',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'lastRewardTime',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'lastUpdateTime',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'lpTokenAddress',
  outputs: [{ internalType: 'address', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'owner',
  outputs: [{ internalType: 'address', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'renounceOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardPerLPToken',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardPerToken',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardRate',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'stake',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [], name: 'startFarming', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'testMint', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newAddr', type: 'address' }],
  name: 'transferDevAddr',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}];

export default {
  address,
  ABI,
};
