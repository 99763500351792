











































































import Vue from 'vue';

export default Vue.extend({
  name: 'StakeNav',
  data() {
    return {
      currentPage: 1,
      dialog: false,
    };
  },
  methods: {
    backHome() {
      this.$router.push({ path: '/' });
    },
    gotoNFT() {
      window.open('https://degacha.com/');
    },
    openDokiPool(id: number) {
      this.currentPage = id;
      this.$router.push({
        path: `/stake/pool/${id}`,
        query: {
          t: String(Date.now()),
        },
      });
    },
  },
  async created() {
    const { href } = window.location;
    const poolNum = href.split('/')[5].split('?')[0];
    this.currentPage = Number(poolNum);
  },
});
