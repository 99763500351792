import { MutationTree, Store } from 'vuex';
import { MusicPlayerStatus } from '@/store/types';
import { State } from './state';

export interface Mutations extends MutationTree<State> {
  setupWeb3(this: Store<State>, state: State, web3: any): void;
  setupApy1(this: Store<State>, state: State, apy1: number): void;
  setupApy2(this: Store<State>, state: State, apy2: number): void;
  setupApy3(this: Store<State>, state: State, apy3: number): void;
  setupApy4(this: Store<State>, state: State, apy4: number): void;
  changeMusicPlayerStatus(this: Store<State>, state: State, payload: MusicPlayerStatus): void;
}

const mutations: Mutations = {
  setupWeb3: (state, web3) => {
    state.web3 = web3;
  },
  setupApy1: (state, apy1) => {
    state.stake.apy1 = apy1;
  },
  setupApy2: (state, apy2) => {
    state.stake.apy2 = apy2;
  },
  setupApy3: (state, apy3) => {
    state.stake.apy3 = apy3;
  },
  setupApy4: (state, apy4) => {
    state.stake.apy4 = apy4;
  },
  setupApy5: (state, apy5) => {
    state.stake.apy5 = apy5;
  },
  setupApy6: (state, apy6) => {
    state.stake.apy6 = apy6;
  },
  setupApy7: (state, apy7) => {
    state.stake.apy7 = apy7;
  },
  setupApy8: (state, apy8) => {
    state.stake.apy8 = apy8;
  },
  changeMusicPlayerStatus(state: State, payload: MusicPlayerStatus) {
    state.musicPlayerStatus = payload;
  },
};

export default mutations;
